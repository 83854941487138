import React from 'react';
import '@fontsource/roboto-mono';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>pecher consulting</h1>
                <br/>
                <p>For any inquiry: requests [at] pecher.co</p>
            </header>
        </div>
    );
}

export default App;
